<template>
  <div id="container" class="guest-forms" v-if="!loading">
    <v-container class="main">
      <h2 class="text-center mt-md-6 mb-md-10 mt-5 page-title"><span>{{ $t('Welcome to Bid4it') }}</span></h2>
      <v-form ref="loginForm" v-model="isFormValid">
        <div class="form-container pa-0" @keyup.enter="login">
          <v-row class="py-0" justify="space-between">
            <v-col class="pb-sm-5 form-column">
              <div class="form-sub-title"><span>{{ $t('Please log in') }}</span></div>
              <v-col class="form-wrapper mx-auto px-0 flex-row">
                <v-col class="px-0">
                  <v-text-field
                    v-model="email"
                    :label="$t('Email')"
                    type="email"
                    prepend-inner-icon="fa-envelope"
                    class="input-field input-field-small-prepend-icon customizable"
                    single-line
                    :class="{ 'error-border': show }"
                    :rules="[validationRequired(), validationEmail()]"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="password"
                    :label="$t('Password')"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-inner-icon="fa-lock"
                    :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                    @click:append="showPassword = !showPassword"
                    @focus="$refs.passwordField.$el.getElementsByTagName('button')[0].tabIndex = -1" ref="passwordField"
                    class="input-field input-field-small-prepend-icon input-field-small-append-icon customizable"
                    single-line
                    :rules="[validationRequired()]"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field>
                  <v-col align="center" class="forgot-password-wrapper no-padding mb-1">
                    <v-icon class="mr-3 bigger-icon">fa-key</v-icon>
                    <a @click="openResetPassword" class="link-text customizable">
                      {{ $t('Forgot password?') }}
                    </a>
                  </v-col>
                  <v-col align="center" class="forgot-password-wrapper no-padding">
                    <v-icon class="mr-3 bigger-icon">fa-unlock</v-icon>
                    <a @click="openResendEmail" class="link-text customizable">
                      {{ $t(`Didn't receive account activation email?`) }}
                    </a>
                  </v-col>
                </v-col>
              </v-col>

              <v-col class="form-wrapper mx-auto pa-0">
                <v-btn
                  class="btn primary-btn login-btn full-width customizable" @click="login"
                  :disabled="loggingLoading || loginButtonDisabled"
                >
                  {{ $t('Sign in') }}
                </v-btn>
              </v-col>

              <v-col class="sn-auth-wrapper mx-auto pa-0" v-if="appFeatures.socialLogin">
                <div class="sn-auth-separator"><span>{{ $t('or') }}</span></div>
                <v-btn class="google-btn sn-auth-btn" dark color="#0076fe" @click="loginWithGoogle" :loading="googleLoading">
                  <v-icon class="mr-1">fab fa-google</v-icon> {{ $t('Log in with Google') }}
                </v-btn>
                <!--<v-btn class="facebook-btn sn-auth-btn" dark color="#3a5a97" @click="loginWithFacebook"
                  :loading="facebookLoading">
                  <v-icon class="mr-1">fab fa-facebook</v-icon> {{ $t('Log in with Facebook') }}
                </v-btn>-->
              </v-col>
            </v-col>
            <div class="form-column__separator" />
            <v-col class="form-column">
              <div class="form-sub-title mb-4"><span>{{ $t("Don't have an account?") }}</span></div>
              <div class="form-text mt-0">
                <p>{{ $t('login_registration_text1') }}</p>
                <ul>
                  <li class="d-flex align-start mb-1" v-if="appFeatures.liveAuctionFunctionality">
                    <v-icon class="green-color-success mr-2 mt-1" small>fa-check</v-icon>{{
                      $t('login_registration_item1')
                    }}
                  </li>
                  <li class="d-flex align-start mb-1">
                    <v-icon class="green-color-success mr-2 mt-1" small>fa-check</v-icon>{{
                      $t('login_registration_item2')
                    }}
                  </li>
                  <li class="d-flex align-start mb-1">
                    <v-icon class="green-color-success mr-2 mt-1" small>fa-check</v-icon>{{
                      $t('login_registration_item3')
                    }}
                  </li>
                </ul>
                <p class="mt-2">{{ $t('login_registration_text2') }}</p>
              </div>
              <v-btn class="btn primary-btn full-width customizable" @click="openRegistration">{{ $t('Sign Up') }}</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
      <div class="form-container pt-0">
        <v-col md="5" offset-md="6">
          <div class="login-choice"><span>{{ $t('or') }}</span></div>
        </v-col>
      </div>
      <div class="form-container pt-0 d-flex align-end flex-column pb-16 guest-section">
        <v-col class="form-column mb-2">
          <div class="form-sub-title mb-4"><span>{{ $t('Continue as guest') }}</span></div>
          <div class="form-text mt-0">
            <ul>
              <li class="d-flex align-start mb-1">
                <v-icon class="green-color-success mr-2 mt-1" small>fa-check</v-icon>{{
                  $t('login_guest_item1')
                }}
              </li>
              <li class="d-flex align-start mb-1">
                <v-icon class="green-color-success mr-2 mt-1" small>fa-check</v-icon>{{
                  $t('login_guest_item2')
                }}
              </li>
            </ul>
          </div>
          <v-row justify='end'>
            <v-col>
              <v-btn class="btn primary-btn customizable" :class="{ 'full-width': $vuetify.breakpoint.mdAndDown, 'mr-2': !$vuetify.breakpoint.mdAndDown }" @click="openDashboard">{{ $t('Continue') }}</v-btn>
              <v-btn v-if="appFeatures.guestAuctionFunctionality" class="btn primary-btn live-auction-btn customizable" :class="{ 'full-width': $vuetify.breakpoint.mdAndDown, 'mt-2': $vuetify.breakpoint.mdAndDown }" @click="openGuestAuction">{{ $t('login_guest_view_live_auction') }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-container>
    <registration ref="registrationModal" />
    <resend-email ref="resendModal" />
    <reset-password ref="resetPassword" />
    <new-password ref="newPassword" />
    <validate-tfa-dialog
      ref="tfaValidation"
      :email="email"
      text="You have logged in from a new device or your last login was a long time ago. For improved security, please enter the verification code that you have received via email"
      @tfaValidationSucceeded=process2FA
      >

    </validate-tfa-dialog>
  </div>
</template>

<script>
import { defaultRoute, localFeatures, timeouts } from '@/utils/constants'
import { mapActions, mapState } from 'pinia'
import Registration from '@/components/modals/guest-registration-modal'
import ResendEmail from '@/components/modals/guest-resend-activation-email-modal'
import ResetPassword from '@/components/modals/guest-reset-password-modal'
import NewPassword from '@/components/modals/guest-new-password-modal'
import * as Sentry from '@sentry/vue';
import { i18n } from '@/main';
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import {getAuthToken, setAuthToken, useCookie} from '@/utils/token';
import ValidateTfaDialog from '@/components/modals/user-validate-tfa-dialog.vue';
import dispatcher from '@/api/dispatch';

export default {
  name: 'LoginView',
  async created() {
    if (this.$route.query.socialLoginError && this.$route.query.socialLoginError === 'true') {
      this.showTooltip(this.$t('Login failed, please try again later'))
    }
    // if the user opens the page and already has a token, redirect to the default page of that user
    if (this.userProfile?.id) {
      await this.$router.push(defaultRoute[this.userProfile.role])
    } else if (getAuthToken()) { //TODO what is this for?
      this.loading = true
      await this.getUserSettings()
      const data = await this.getCurrentUserProfile()
      this.SET_USER_DATA(data)
      let foundLang = this.appLocalization.i18n.availableLanguages.find((it) => it.lang === this.userProfile.language)
      this.$i18n.locale = foundLang.lang
      i18n.locale = foundLang.lang
      this.APP_GLOBAL_STORE_LANG(foundLang.lang)
      await this.$router.push(defaultRoute[this.userProfile.role])
    }
  },

  async mounted() {
    if (this.$route.name === 'registration') {
      this.$refs.registrationModal.openModal()
    } else if (this.$route.name === 'resendEmail') {
      this.$refs.resendModal.openModal()
    } else if (this.$route.name === 'resetPassword') {
      this.$refs.resetPassword.openModal()
    } else if (this.$route.name === 'resetNewPassword') {
      this.$refs.newPassword.openModal()
    } else if (this.$route.name === 'login') {
      if (!this.$route.query.code) return
      let formData = { code: this.$route.query.code }

      try {
        await dispatcher.confirmUserRegistration(formData)

        this.$swal.fire({
          position: 'center',
          icon: 'success',
          title: this.$t('Almost done'),
          text: this.$t('Your account has been activated successfully but still awaits confirmation from the auction house. You will receive an additional e-mail once done.'),
          showConfirmButton: false,
          timer: timeouts.closeSweetalert
        })
      } catch (e) {
        this.$swal.fire({
          position: 'center',
          icon: 'error',
          title: this.$t('An error has occured'),
          text: this.$t('The code you tried to use is either expired or was used already'),
          showConfirmButton: false,
          timer: timeouts.closeSweetalert
        })
      }
    }
  },
  emits: ['showCookieBanner'],
  data: () => ({
    email: null,
    password: null,
    show: false,
    showPassword: false,
    msg: '',
    toolTipPosition: 'right',
    loading: false,
    loggingLoading: false,
    facebookLoading: false,
    googleLoading: false,
    features: localFeatures,
    isFormValid: false
  }),
  components: {
    ValidateTfaDialog,
    Registration,
    ResendEmail,
    ResetPassword,
    NewPassword
  },
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appSettings', 'appFeatures', 'appStoreLang', 'appLocalization', 'cookiesStatus', 'loginButtonDisabled', 'appFeatures', 'isMobile']),
  },
  methods: {
    validationEmail: frontendValidations.email,
    validationRequired: frontendValidations.required,
    ...mapActions(useAuctionStore, ['SET_USER_DATA']),
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'getUserSettings', 'getCurrentUserProfile', 'APP_GLOBAL_STORE_LANG', 'SET_ADMIN_DRAWER', 'SET_TEMP_ALERT']),
    signUp() {
      this.$router.push({ name: 'registration' })
    },
    async process2FA(result) {
      this.$refs.tfaValidation.closeModal()
      this.$swal.fire({
        position: 'center',
        icon: 'success',
        title: this.$t('Done'),
        text: this.$t('Security verification completed, logging in'),
        showConfirmButton: false,
        timer: 2000
      })
      await this.processLogin(result);
    },
    async processLogin(response) {
      try {
        if (!useCookie()) {
          setAuthToken(response.token)
        }

        await this.getUserSettings()
        const resp = await this.getCurrentUserProfile()

        // watcher (App.vue) for userProfile will init socket connection
        this.SET_USER_DATA(resp)

        if (localFeatures.useSentry) {
          Sentry.setUser({ email: resp.email });
        }

        let foundLang = this.appLocalization.i18n.availableLanguages.find(it => it.lang === this.userProfile.language)
        this.$i18n.locale = foundLang.lang
        i18n.locale = foundLang.lang
        this.APP_GLOBAL_STORE_LANG(foundLang.lang)
        this.showPassword = false
        this.SET_ADMIN_DRAWER(true)
        await this.$router.push(this.$route.query.nextUrl || defaultRoute[resp.role])
      } catch (e) {
        if (e.data?.error) {
          this.showTooltip(this.$t(e.data.data[0].errorShortText))
        }
      }
    },
    async login() {
      this.$refs.loginForm.validate()

      if (this.isFormValid) {
        if (!this.cookiesStatus) {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: this.$t('An error has occured'),
            text: this.$t('You have to accept cookies for the application to work'),
            showConfirmButton: false,
            timer: timeouts.closeSweetalert
          })
          this.$root.$emit('showCookieBanner');
          return
        }
        this.loggingLoading = true

        try {
          let result = await dispatcher.signIn(this.email, this.password)

          if (result.tfa_required) {
            this.UPDATE_GLOBAL_STATE({
              key: 'showTfaDialog',
              value: true,
            })
          } else {
            await this.processLogin(result);
          }
        } catch (e) {
          if (e.data?.error) {
            this.showTooltip(this.$t(e.data.data[0].errorShortText))
          }
        } finally {
          this.loggingLoading = false
        }
      }
    },
    showTooltip(message) {
      this.SET_TEMP_ALERT({ flavor: 'error', content: message });
    },
    openResendEmail() {
      this.$router.push({ name: 'resendEmail' })
      this.$refs.resendModal.openModal()
    },
    openRegistration() {
      this.$router.push({ name: 'registration' })
      this.$refs.registrationModal.openModal()
    },
    openResetPassword() {
      this.$router.push({ name: 'resetPassword' })
      this.$refs.resetPassword.openModal()
    },
    openNewPassword() {
      this.$router.push({ name: 'resetNewPassword' })
      this.$refs.newPassword.openModal()
    },
    openDashboard() {
      this.$router.push({ name: 'dashboard' })
    },
    openGuestAuction() {
      this.$router.push({ name: 'guestLiveAuction' })
    },
    async loginWithGoogle() {
      try {
        this.googleLoading = true
        window.location = `${this.appSettings.url_backend}api/v2/auth/login-google`
        this.googleLoading = false
      } catch (e) {
        this.googleLoading = false
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('An error occured trying login with Google') })
      }
    },
    async loginWithFacebook() {
      try {
        this.facebookLoading = true
        const result = await dispatcher.loginWithFacebook()
        this.facebookLoading = false

        if (!useCookie()) {
          setAuthToken(result.token)
        }

        this.APP_GLOBAL_STORE_LANG(result.language)
        delete result.token

        this.UPDATE_GLOBAL_STATE({
          key: 'userProfile',
          value: result,
        })

        await this.$router.push(this.$route.query.nextUrl || defaultRoute[result.role])
      } catch (e) {
        this.facebookLoading = false
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('An error occured trying login with Facebook') })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.forgot-password-wrapper {
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto !important;

  @media screen and (max-width: 968px) {
    height: auto;
  }

  >.row {
    max-width: 100%;
  }
}

.guest-forms {
  @media screen and (max-width: 968px) {
    padding-top: 80px;
  }

  @media screen and (min-width: 968px) {
    .main {
      margin-top: 80px;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;

    @media screen and (max-width: 968px) {
      flex-direction: row;
    }
  }
}

.page-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.02em;

  @media(max-width: 968px) {
    font-size: 24px;
  }
}

.form-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.15);
  padding: 20px 52px;
  border-radius: 8px;
  flex: 0 0 45%;
  max-width: 45%;

  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100% !important;
    margin-bottom: 15px;
    box-shadow: none;
    padding-left: 8px;
    padding-right: 8px;
  }

  &__separator {
    border-right: 1px solid #cecece;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
      border-right: none;
      border-bottom: 1px solid #9e9e9e;
      width: 100%;
    }
  }
}

.second-section {
  margin-right: -2%;

  .form-column {
    max-width: 46%;
  }

}

.login-choice {
  @media screen and (max-width: 767px) {
    margin: -28px 0px -14px 0px;
  }

  span {
    display: -ms-grid;
    display: grid;
    font-size: 16px;
    width: 70%;
    line-height: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    -ms-grid-columns: minmax(50px, 1fr) auto minmax(50px, 1fr);
    grid-template-columns: minmax(70px, 1fr) auto minmax(70px, 1fr);
    grid-gap: 50px;
    margin-left: 20%;

    @media screen and (max-width: 767px) {
      margin-left: 0px;
      width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 44%;
      float: right;
    }

    &:after,
    &:before {
      content: "";
      border-top: 1px solid #cecece;

      @media screen and (max-width: 767px) {
        border-top: 1px solid #9e9e9e;
      }
    }
  }
}

.form-text {
  margin-top: 50px;
  min-height: 170px;
  margin-bottom: 0;
}

.guest-section {
  .form-text {
    min-height: 75px;
  }
}

.guest-forms {
  .form-title {
    @media(max-width: 968px) {
      font-size: 21px;
    }
  }
}

.sn-auth-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sn-auth-separator {
  position: relative;
  min-width: 100%;
  text-align: center;
  margin: 20px 0 10px;

  span {
    position: relative;
    background: #fff;
    padding: 0 10px;
    color: #cecece;
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    margin-top: -1px;
    width: 100%;
    background: #cecece;
    height: 1px;
  }
}

.sn-auth-btn {
  text-transform: none;
  letter-spacing: 0;
  box-shadow: none !important;
  width: calc(50% - 5px);
  min-width: 160px !important;
  margin-bottom: 15px;

  .v-ripple__container {
    display: none !important;
  }

  @media screen and (max-width: 968px) {
    font-size: 12px !important;
    padding: 0 6px !important;
  }

  @media(max-width: 480px) {
    width: 100%;
  }
}

.live-auction-btn {
  max-width: 200px !important;

  @media screen and (max-width: 1300px) {
    max-width: 240px !important;
  }
}
</style>
