<template>
  <div class="catalogue list-view full-width pb-0">
    <div
      :key="auction.id"
      class="catalogue__item"
      :class="{ started: auction.status === 'started' || auction.status === 'paused' }"
      v-if="auction.type !== 'sale'"
    >
      <div
        class="catalogue__image"
        v-if="auction.banner_image"
      >
        <img :src="auction.banner_image">
      </div>
      <div class="catalogue__item-content full-width">
        <div class="catalogue__meta">
          <div class="d-flex flex-wrap">
            <div class="catalogue__meta-item">
              <p class="catalogue__meta-name">{{ $t('Type') }}</p>
              <h5 class="catalogue__meta-value">{{ $t(auction.type) }}</h5>
            </div>
            <div class="catalogue__meta-item">
              <p class="catalogue__meta-name">{{ $t('Lot count') }}</p>
              <h5 class="catalogue__meta-value">{{ auction.count_articles ? auction.count_articles : '-' }}</h5>
            </div>
            <div class="catalogue__meta-item" v-if="auction.estimated_price">
              <p class="catalogue__meta-name">{{ $t('Estimated price') }}</p>
              <h5 class="catalogue__meta-value">{{ currency }} {{ auction.estimated_price }}</h5>
            </div>
            <div class="catalogue__meta-item" v-if="auction.type === 'timed' && auction.start_at">
              <p class="catalogue__meta-name">{{ $t('Starts at') }}</p>
              <div class="catalogue__meta-list">
                <h5 class="catalogue__meta-value">
                  {{ getFormattedDate(auction.start_at, appStoreLang, false, false) }}
                </h5>
              </div>
            </div>
            <div class="catalogue__meta-item" v-else-if="auction.sale_dates && auction.sale_dates.dates && auction.sale_dates.dates.length">
              <p class="catalogue__meta-name">{{ $t('Starts at') }}</p>
              <div class="catalogue__meta-list">
                <h5
                  class="catalogue__meta-value"
                  v-for="(date, i) in auction.sale_dates.dates"
                  :key="i"
                >
                  {{ getFormattedDate(date, appStoreLang, false, false) }}
                </h5>
              </div>
            </div>
            <div class="catalogue__meta-item" v-if="auction.type !== 'timed' && auction.preview_data && auction.preview_data.dates && auction.preview_data.dates.length">
              <p class="catalogue__meta-name">{{ $t('Preview dates') }}</p>
              <div class="catalogue__meta-list">
                <h5
                  class="catalogue__meta-value"
                  v-for="(date, i) in auction.preview_data.dates"
                  :key="i"
                >
                  {{ getFormattedDate(date.starts_at, appStoreLang, false, false) }} - {{ moment(date.ends_at).format('HH:mm') }}
                </h5>
              </div>
            </div>
            <div class="catalogue__meta-item" v-if="!isMobile && auction.type !== AuctionFlavor.sale && showTotalCommissionCosts">
              <p class="catalogue__meta-name">{{ $t('Total additional costs') }}</p>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="catalogue__meta-value icon-with-hover">
                    <v-icon >fa-question-circle</v-icon>
                    <span class="ml-1">{{ getTotalCostsText(auction) }} %</span>
                  </div>
                </template>
                <span>
                    <div class="d-flex justify-space-between" v-if="(auction.type === AuctionFlavor.live || auction.type === AuctionFlavor.timed) && auction.surcharge">
                      <span class="catalogue__meta-name">{{ $t('Buyers premium') }}</span>
                      <span class="catalogue__meta-name"> {{ auction.surcharge }} %</span>
                    </div>
                    <div class="d-flex justify-space-between" v-if="auction.type === AuctionFlavor.live && auction.signup_enabled && appSettings.commission.live !== 0">
                      <span class="catalogue__meta-name">{{ $t('Live-bidding commission') }}</span>
                      <span class="catalogue__meta-name"> {{ appSettings.commission.live }} %</span>
                    </div>
                    <div class="d-flex justify-space-between" v-if="auction.tax !== 0">
                      <span class="catalogue__meta-name">{{ $t('Tax') }}</span>
                      <span class="catalogue__meta-name">{{ auction.tax }} %</span>
                    </div>
                    <div class="d-flex justify-space-between mt-2" v-if="auction.type !== AuctionFlavor.sale && showTotalCommissionCosts">
                      <span class="catalogue__meta-name">{{ $t('Total additional costs') }} {{ getTotalCostsText(auction) }} %</span>
                    </div>
                </span>
              </v-tooltip>
            </div>
            <div class="catalogue__meta-item" v-if="((!isMobile && !showTotalCommissionCosts) || (isMobile)) && (auction.type === AuctionFlavor.live || auction.type === AuctionFlavor.timed) && auction.surcharge">
              <p class="catalogue__meta-name">{{ $t('Surcharge') }}</p>
              <h5 class="catalogue__meta-value">{{ auction.surcharge }} %</h5>
            </div>
            <div class="catalogue__meta-item" v-if="((!isMobile && !showTotalCommissionCosts) || (isMobile)) && auction.type === AuctionFlavor.live && auction.signup_enabled && appSettings.commission.live !== 0">
              <p class="catalogue__meta-name">{{ $t('Live-bidding commission') }}</p>
              <h5 class="catalogue__meta-value">{{ appSettings.commission.live }} %</h5>
            </div>
            <div class="catalogue__meta-item" v-if="((!isMobile && !showTotalCommissionCosts) || (isMobile)) && auction.tax !== 0 && auction.type !== AuctionFlavor.sale">
              <p class="catalogue__meta-name">{{ $t('Tax') }}</p>
              <h5 class="catalogue__meta-value">{{ auction.tax }} %</h5>
            </div>
            <div class="catalogue__meta-item" v-if="isMobile && showTotalCommissionCosts && auction.type !== AuctionFlavor.sale">
              <p class="catalogue__meta-name">{{ $t('Total additional costs') }}</p>
              <h5 class="catalogue__meta-value">{{ getTotalCostsText(auction) }} %</h5>
            </div>
          </div>
        </div>
        <p class="catalogue__description">{{ auctionDescription(auction) }}</p>
        <div class="catalogue__footer">
          <div class="catalogue__footer-item customizable" v-if="auction.terms && auction.terms.length">
            <a class="catalogue__footer-value" @click="openTermsDialog(auction)"><v-icon class="mr-1" small>fa-info-circle</v-icon>{{ $t('Terms') }}</a>
          </div>
          <div class="catalogue__footer-item customizable" v-if="auction.shipping_data && auction.shipping_data.length && !auction.no_shipping">
            <a class="catalogue__footer-value" @click="openShippingDialog(auction)"><v-icon class="mr-1" small>fa-truck</v-icon>{{ $t('Shipping information') }}</a>
          </div>
          <div class="catalogue__footer-item" v-else-if="auction.no_shipping">
            <v-tooltip top allow-overflow>
              <template #activator="{ on, attrs }">
                <svg
                  v-bind="attrs"
                  v-on="on"
                  height="24px"
                  width="24px"
                  fill="#000000"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 101"
                  viewBox="0 0 100 100" x="0px" y="0px"
                  class="catalogue__item-shipping mr-2"
                >
                  <path d="M50,90A40,40,0,1,0,10,50,40,40,0,0,0,50,90Zm0-73.23a33.1,33.1,0,0,1,21,7.46l-4.39,4.39a.63.63,0,0,1,.16.26L69,35.76,75.77,29A33.23,33.23,0,0,1,29,75.77l4.13-4.13h-2.4a.76.76,0,0,1-.75-.75V65.21L24.23,71A33.23,33.23,0,0,1,50,16.77Z" /><path d="M68.5,70.14V39.56H54v8.87a.76.76,0,0,1-.75.75H46.79a.76.76,0,0,1-.75-.75V39.56H31.5V70.14Z" /><polygon points="31.79 38.05 46.08 38.05 46.53 29.86 34.5 29.86 31.79 38.05" /><polygon points="53.92 38.05 68.21 38.05 65.5 29.86 53.47 29.86 53.92 38.05" /><polygon points="52.42 38.05 51.96 29.86 48.04 29.86 47.58 38.05 52.42 38.05" /><rect x="47.54" y="39.56" width="4.92" height="8.13" />
                </svg>
              </template>
              <span>{{ $t('No shipping possible') }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div
      :key="auction.id"
      class="catalogue__item"
      :class="{ started: auction.status === 'started' || auction.status === 'paused' }"
      v-else-if="auction.type === 'sale' && auction.status === 'started' && auction.count_articles > 0"
    >
      <div
        class="catalogue__image"
        v-if="auction.banner_image"
      >
        <img :src="auction.banner_image">
      </div>
      <div class="catalogue__item-content full-width">
        <div class="catalogue__header">
          <h3 class="catalogue__name">{{ auctionTitle(auction) }}</h3>
        </div>
        <div class="catalogue__meta">
          <div class="d-flex flex-wrap">
            <div class="catalogue__meta-item">
              <p class="catalogue__meta-name">{{ $t('Article count') }}</p>
              <h5 class="catalogue__meta-value">{{ auction.count_articles ? auction.count_articles : '-' }}</h5>
            </div>
          </div>
        </div>
        <p class="catalogue__description">{{ auctionDescription(auction) }}</p>
        <div class="catalogue__footer">
          <div class="catalogue__footer-item" v-if="auction.terms && auction.terms.length">
            <a class="catalogue__footer-value" @click="openTermsDialog(auction)"><v-icon class="mr-1" small>fa-info-circle</v-icon>{{ $t('Terms') }}</a>
          </div>
          <div class="catalogue__footer-item" v-if="auction.shipping_data && auction.shipping_data.length">
            <a class="catalogue__footer-value" @click="openShippingDialog(auction)"><v-icon class="mr-1" small>fa-truck</v-icon>{{ $t('Shipping information') }}</a>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="termsDialog"
      width="500"
      scrollable
      persistent
      @keydown.esc="termsDialog = false"
    >
      <v-card class="py-4">
        <v-btn class="modal-close-btn icon-with-hover" icon @click="termsDialog = false"><v-icon>fa-times</v-icon></v-btn>
        <v-card-title class="justify-center pa-0 mb-4">
          <span class="headline">{{ $t('Terms') }}</span>
        </v-card-title>
        <v-card-text>
          <span v-if="useHtml" class="useHtmlData" v-html="termsDialogData"></span>
          <span v-else>{{ termsDialogData }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shippingDialog"
      width="500"
      scrollable
      persistent
      @keydown.esc="shippingDialog = false"
    >
      <v-card class="py-4">
        <v-btn class="modal-close-btn icon-with-hover" icon @click="shippingDialog = false"><v-icon>fa-times</v-icon></v-btn>
        <v-card-title class="justify-center pa-0 mb-4">
          <span class="headline">{{ $t('Shipping information') }}</span>
        </v-card-title>
        <v-card-text>
          <span v-if="useHtml" class="useHtmlData" v-html="shippingDialogData"></span>
          <span v-else>{{ shippingDialogData }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import useRootStore from '@/stores/rootStore';
import { getFormattedDate, getTranslatedText } from '@/services/i18n';
import { AuctionFlavor, localFeatures } from '@/utils/constants';

export default {
  name: 'AuctionOverview',
  components: {
  },
  props: {
    auction: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      termsDialog: false,
      shippingDialog: false,
      termsDialogData: null,
      shippingDialogData: null,
      useHtml: localFeatures.useHtml,
      showTotalCommissionCosts: localFeatures.showTotalCommissionCosts
    }
  },
  computed: {
    ...mapState(useRootStore, ['appSettings']),
    AuctionFlavor() {
      return AuctionFlavor
    },
    ...mapState(useRootStore, ['appStoreLang', 'isMobile']),
  },
  methods: {
    getFormattedDate,
    auctionDescription (auction) {
      return getTranslatedText(auction, 'description', this.appStoreLang)
    },
    openTermsDialog(auction) {
      this.termsDialog = true
      this.termsDialogData = getTranslatedText(auction, 'terms', this.appStoreLang)
    },
    openShippingDialog(auction) {
      this.shippingDialog = true
      this.shippingDialogData = getTranslatedText(auction, 'shipping_data', this.appStoreLang)
    },
    getTotalCostsText(auction) {
      const multiplier = (auction.tax > 0) ? ((auction.tax / 100) + 1) : 1
      let commission = 0
      if (auction.type !== AuctionFlavor.sale) {
        commission = (auction.type === AuctionFlavor.live && !auction.signup_enabled) ? 0 : this.appSettings.commission[auction.type]
      }
      return ((auction.surcharge + commission) * multiplier).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
.catalogue {
  @media (max-width: 968px) {
    .catalogue__item {
      display: flex;
    }
    .catalogue__image {
      margin-right: 25px;
    }
  }
  &.list-view {
    .catalogue__item {
      width: 100%;
      max-width: 100%;
      display: flex;
      margin-right: 0;
      margin-left: 0;
    }
    .catalogue__image {
      min-width: 20%;
      width: 20%;
      margin-right: 25px;
      @media (max-width: 968px) {
        min-width: 100%;
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }
  &__item {
    border-radius: 0;
    @media (max-width: 968px) {
      flex-wrap: wrap;
    }
    &.started {
      border-width: 2px;
      border-color: green !important;
    }
  }
  &__item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__meta {
    justify-content: space-between;
    @media (max-width: 968px) {
      flex-direction: column-reverse;
    }
  }
  &__footer {
    position: relative;
    margin-top: 25px;
    display: flex;
    align-items: flex-start;
    top: 10px;
    @media (max-width: 968px) {
      top: 0;
    }
  }
  &__meta-item,
  &__footer-item {
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid #E5E5E5;
    @media (max-width: 968px) {
      display: flex;
      border: none;
      flex-wrap: wrap;
    }
  }
  &__meta-item {
    @media (max-width: 968px) {
      flex: 100%;
      flex-direction: column;
    }
  }
  &__meta-value,
  &__footer-value {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  &__footer-item,
  &__footer-value {
    cursor: pointer;
    @media (max-width: 968px) {
      font-size: 11px;
    }
  }
  &__meta-list {
    text-align-last: justify;
    @media (max-width: 968px) {
      text-align-last: start;
    }
  }
  &__name {
    margin-bottom: 0;
    @media (max-width: 968px) {
      display: -webkit-box;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  &__description {
    margin: 25px 0 0;
    @media (max-width: 968px) {
      color: #000;
    }
  }
  &__header {
    @media (max-width: 968px) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
}

</style>
